:root {
  --primary-color: #ffa500;
  --secondary-color: #ff8c00;
  --background-color: #1a0f00;
  --card-background: rgba(26, 15, 0, 0.8);
  --text-color: #ffe0b3;
  --neon-glow: 0 0 10px rgba(255, 165, 0, 0.7), 0 0 20px rgba(255, 165, 0, 0.5), 0 0 30px rgba(255, 165, 0, 0.3);

}

*{
  font-family: quicksand;
}


body {
  margin: 0;
  padding: 0;
  background-color: var(--background-color);
  color: var(--text-color);
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
  transition: background-color 1.5s ease, color 1.5s ease;
}



.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}


/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: var(--card-background);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 1.5s ease, box-shadow 1.5s ease;
}

/* Cards */
.featured-card, .documentation-card {
  background-color: var(--card-background);
  border-radius: 8px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin: 1rem;
  border-radius: 12px;
}

.featured-card:hover, .documentation-card:hover {
  transform: translateY(-10px) scale(1.05);
  box-shadow: var(--neon-glow);
}

.featured-card .card-icon, .documentation-card .card-icon {
  margin-bottom: 1rem;
  color: var(--primary-color);
  transition: transform 0.3s ease;
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.featured-card:hover .card-icon, .documentation-card:hover .card-icon {
  transform: scale(1.2);
}

.featured-card h3, .documentation-card h3 {
  margin-top: 0;
  color: var(--primary-color);
  font-size: 1.5rem;
  text-shadow: var(--neon-glow);
}

.featured-card p, .documentation-card p {
  color: var(--text-color);
}

.featured-card a, .documentation-card a {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: var(--primary-color);
  color: var(--text-color);
  font-weight: bolder;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.featured-card a:hover, .documentation-card a:hover {
  background-color: var(--secondary-color);
  box-shadow: var(--neon-glow);

}



.featured-grid,
.documentation-grid {
  gap: 2rem;
}

/* Footer */
.footer {
  background-color: var(--card-background);
  color: var(--text-color);
  padding: 1rem;
  text-align: center;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
}






.group-buttons {
  display: grid;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
}

.group-button {
  background-color: var(--card-background);
  color: var(--primary-color);
  font-size: 1.1rem;
  font-weight: bold;
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  padding: 0.5rem 1rem;

  cursor: pointer;
  transition: transform 0.5s ease, box-shadow 0.5s ease, background-color 0.5s ease;
  position: relative;
  overflow: hidden;
}

.group-button::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0;
  background-color: var(--primary-color);
  transition: height 0.3s ease;
  z-index: -1;
}

.group-button:hover::before,
.group-button.active::before {
  height: 100%;
}

.group-button:hover {
  background-color: var(--primary-color);
  box-shadow: var(--neon-glow);
  color: var(--background-color);
  transform: translateY(-3px);
}

.group-button.active {
  background-color: var(--primary-color);
  box-shadow: var(--neon-glow);
  color: var(--background-color);
  font-size: 1.2rem;
}


.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: var(--card-background);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 1.5s ease, box-shadow 1.5s ease;
}

.navbar-title {
  font-size: 1.5rem;
  color: var(--primary-color);
  margin: 0;
  text-shadow: var(--neon-glow);
  transition: color 1.5s ease, text-shadow 1.5s ease;
}

.navbar-brand {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.navbar-actions {
  display: flex;
  align-items: center;
}


.navbar-logo {
  width: 30px;
  height: 30px;
}

.social-links a {
  color: var(--primary-color);
  text-decoration: none;
  margin-left: 1rem;
  text-shadow: var(--neon-glow);
  position: relative;
  padding-bottom: 5px;
}

.social-links a::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--primary-color);
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.social-links a:hover::after {
  transform: scaleX(1);
}

.centered-content {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.centered-content h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.centered-content p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.centered-content h2 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
}


.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.content h1 {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.content h2 {
  font-size: 2rem;
  color: var(--secondary-color);
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.content h3 {
  font-size: 1.5rem;
  color: var(--primary-color);
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}

.content p {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
}

.content table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
}

.content table td {
  padding: 0.5rem;
  border: 1px solid var(--primary-color);
}

.content table td:first-child {
  font-weight: bold;
  width: 30%;
}

.content a {
  color: var(--primary-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

.content a:hover {
  color: var(--secondary-color);
  text-decoration: underline;
}


.featured-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-bottom: 4rem;
}

.featured-card {
  background-color: var(--card-background);
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  backdrop-filter: blur(5px);
}

.featured-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: -1;
}

.featured-card:hover {
  transform: translateY(-10px) scale(1.05);
  box-shadow: var(--neon-glow);
}

.featured-card:hover::before {
  opacity: 0.2;
}

.featured-card .card-icon {
  margin-bottom: 1rem;
  color: var(--primary-color);
  transition: transform 0.3s ease;
}

.featured-card:hover .card-icon {
  transform: scale(1.2);
}

.featured-card h3 {
  margin-top: 0;
  color: var(--primary-color);
  font-size: 1.5rem;
  text-shadow: var(--neon-glow);
}

.featured-card p {
  color: var(--text-color);
  margin-bottom: 1rem;
}

.featured-card a {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: var(--primary-color);
  color: black;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.featured-card a:hover {
  background-color: var(--secondary-color);
  box-shadow: var(--neon-glow);
}

.all-docs-section {
  display: flex;
  gap: 2rem;
}

.search-sidebar {
  flex: 0 0 250px;
  padding: 1rem;
  background-color: var(--card-background);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.search-sidebar h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: var(--primary-color);
  text-shadow: var(--neon-glow);
}

.documentation-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1.5rem;
  flex-grow: 1;
  justify-content: center;
}

.documentation-card {
  background-color: var(--card-background);
  border-radius: 8px;
  padding: 1.5rem;
  text-align: center;
  text-decoration: none;
  color: var(--text-color);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 150px;
  backdrop-filter: blur(5px);
}

.documentation-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--neon-glow);
}

.documentation-card .card-icon {
  margin-bottom: 0.75rem;
  color: var(--primary-color);
  font-size: 2rem;
}

.documentation-card h3 {
  font-size: 1.2rem;
  margin: 0;
}

.search-bar {
  display: flex;
  flex-direction: column;
}

.search-bar input {
  flex-grow: 1;
  padding: 0.75rem;
  border: 1px solid var(--primary-color);
  border-radius: 4px 0 0 4px;
  font-size: 1rem;
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--text-color);
  box-sizing: border-box;
}

.search-bar button {
  font-size: 12rem;
  background-color: var(--primary-color);
  color: var(--text-color);
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-bar button:hover {
  background-color: var(--secondary-color);
  box-shadow: var(--neon-glow);
}


.footer {
  background-color: var(--card-background);
  color: var(--text-color);
  padding: 1rem;
  text-align: center;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

.footer::before {
  content: '';
  position: absolute;
  top: -10px;
  left: 0;
  width: 100%;
  height: 10px;
  background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
  filter: blur(5px);
}

@media (max-width: 1024px) {
  .featured-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: center;
  }

  .social-links {
    margin-top: 1rem;
  }

  .content {
    padding: 1rem;
  }

  .featured-grid {
    grid-template-columns: 1fr;
  }

  .all-docs-section {
    flex-direction: column;
  }

  .search-sidebar {
    flex: 0 0 auto;
    margin-bottom: 1rem;
  }

  .documentation-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}


/* Custom scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--background-color);
}

::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--secondary-color);
}

/* Add some additional animations and effects */
@keyframes neon-pulse {
  0% {
    box-shadow: 0 0 5px var(--primary-color), 0 0 10px var(--primary-color);
  }
  50% {
    box-shadow: 0 0 10px var(--primary-color), 0 0 20px var(--primary-color), 0 0 30px var(--primary-color);
  }
  100% {
    box-shadow: 0 0 5px var(--primary-color), 0 0 10px var(--primary-color);
  }
}

.featured-card:hover {
  animation: neon-pulse 1.5s infinite;
}

.documentation-card:hover .card-icon {
  transform: scale(1.1) ;
  transition: transform 0.5s ease;
}

/* Improve accessibility */
.search-bar input:focus,
.search-bar button:focus,
.featured-card a:focus,
.documentation-card:focus {
  outline: 2px solid var(--secondary-color);
  outline-offset: 2px;
}

/* Add a subtle background pattern */
body::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
    linear-gradient(to right, rgba(255, 0, 255, 0.1) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(255, 0, 255, 0.1) 1px, transparent 1px);
  background-size: 20px 20px;
  z-index: -1;
}

/* Enhance the search bar */
.search-bar {
  position: relative;
}

.search-bar input {
  width: 100%;
  padding-right: 40px;
}

.search-bar button {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: var(--primary-color);
  font-size: 1.2rem;
}

.search-bar button:hover {
  background-color: rgba(255, 0, 255, 0.1);
}

/* Add a scrollbar style for webkit browsers */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--background-color);
}

::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--secondary-color);
}

/* Add a hover effect for social links */
.social-links a {
  color: var(--primary-color);
  text-decoration: none;
  margin-left: 1rem;
  text-shadow: var(--neon-glow);
  position: relative;
  display: inline-block;
  padding-bottom: 3px;
}

.social-links a::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--primary-color);
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease;
}

.social-links a:hover::after {
  transform: scaleX(1);
}

/* Improve card readability */
.featured-card,
.documentation-card {
  backdrop-filter: blur(5px);
  background-color: rgba(42, 42, 42, 0.8);
}

/* Add a subtle text shadow to improve contrast */
body {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

/* Enhance the footer */
.footer {
  position: relative;
  overflow: hidden;
}

.footer::before {
  content: '';
  position: absolute;
  top: -10px;
  left: 0;
  width: 100%;
  height: 10px;
  background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
  filter: blur(5px);
}
